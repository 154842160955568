import React, { useId } from 'react'
import './styles.scss'

const Option = ({ opt }) => {
  const id = useId()
  return (
    <option key={id} value={opt === 'Todas as opções' ? '' : opt}>
      <span>{opt}</span>
    </option>
  )
}

export const Filter = ({
  handleFilterChange,
  name,
  options,
  orientation,
  size,
  title,
  value,
}) => {
  const uniqueOptions = Array.from(new Set(options))

  return (
    <div className={`filter-container filter-container--${orientation}`}>
      <p className="filter__title">{title}</p>
      <select
        name={name}
        className={`filter__selector${size ?? ''}`}
        value={value}
        onChange={handleFilterChange}
      >
        {uniqueOptions.map((opt) => {
          return <Option opt={opt} />
        })}
      </select>
    </div>
  )
}
