import React from "react"
import "./styles.scss"

const WorkWithUs = () => (
  <div className="work-with-us">
    <div>
      <p>#VemSerDR_</p>
    </div>
  </div>
)

export default WorkWithUs
