import { GatsbyImage } from 'gatsby-plugin-image'
import React, { forwardRef, useCallback, useEffect, useRef, useState } from 'react'
import { EffectFade } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import drInstitucional from '../../assets/videos/dr-institucional.mp4'
import Button from '../../components/molecules/Button'
import { CircledArrow } from '../../components/molecules/Icons'
import SectionContainer from '../../components/molecules/SectionContainer'
import Slogan from '../../components/molecules/Slogan'
import WorkWithUs from '../../components/molecules/WorkWithUs'
import HighLight from '../../components/organisms/HighLight'
import Seo from '../../helpers/seo'
import useStaticImage from '../../hooks/useStaticImage'
import useWindowDimensions from '../../hooks/useWindowDimensions'
import Layout from '../../templates/layout'
import benefitsList from './benefits.json'
import testimonialInformation from './testimonialInformation.json'

import 'swiper/css'
import 'swiper/css/effect-fade'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import { Filter } from '../../components/atoms/Filter'
import JobsPagination from '../../components/molecules/JobsPagination'
import VideoPlayer from '../../components/molecules/VideoPlayer'
import { EMAIL_OPPORTUNITY_CONTACT } from '../../helpers/constants'
import useContentfulJobs from '../../hooks/useContentfulJobs'
import './styles.scss'

const Banner = ({ onClick }) => {
  return (
    <SectionContainer>
      <div className="banner">
        <div className="banner__title">
          <h2 className="banner__title__vertical">#VemSer</h2>
          <svg
            className="banner__title__horizontal"
            width="458"
            height="318"
            viewBox="0 0 458 318"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M126.797 21.3989C113.269 7.13615 93.4385 0.00967789 67.2949 0.00967789H0V317.33H63.2856C90.3212 317.33 111.044 310.272 125.454 296.156C139.864 282.05 147.069 260.132 147.069 230.42V82.0085C147.069 55.8649 140.315 35.6617 126.797 21.3989ZM101.614 234.87C101.614 248.545 98.9377 258.573 93.5953 264.955C88.2431 271.346 79.1757 274.532 66.4029 274.532H45.4547V42.7882H66.4029C79.7737 42.7882 88.9783 46.1996 94.0365 53.032C99.0848 59.8742 101.614 69.52 101.614 82.0085V234.87Z"
              fill="black"
            />
            <path
              d="M261.161 172.477C275.718 164.458 285.893 153.911 291.696 140.834C297.489 127.757 300.381 111.858 300.381 93.1444C300.381 31.0449 273.649 0 220.166 0H147.068V317.33H192.523V181.838H219.716L262.053 317.32H310.184L261.161 172.477ZM253.593 113.426C252.701 119.523 251.064 124.65 248.682 128.806C246.006 132.972 242.291 136.158 237.546 138.383C232.782 140.618 226.401 141.726 218.373 141.726H192.523V42.7884H216.147C231.89 42.7884 242.585 46.9447 248.231 55.2671C250.917 59.7175 252.691 65.0011 253.584 71.0885C254.466 77.1857 254.917 84.2338 254.917 92.2622C254.917 100.29 254.476 107.348 253.593 113.426Z"
              fill="black"
            />
            <path d="M458 317.328V291.38H313.637V317.328H458Z" fill="black" />
          </svg>
        </div>
        <div className="banner__paragraph">
          <p>
            Desde 2015, surgimos com o propósito de levar uma experiência diferenciada de
            geração de resultados para os nossos clientes. Isso só foi possível porque,
            por trás de cada solução digital personalizada entregue,{' '}
            <b>existe um time com a vontade de fazer acontecer, dia após dia.</b>
            <br />
            <br />
            Nossas realizações ultrapassaram as fronteiras brasileiras, pois já
            desenvolvemos projetos para os EUA, Londres, Bélgica, Dinamarca e Uruguai. E
            sabemos que é só o começo, afinal, por trás de cada tecnologia moderna, estão
            profissionais talentosos que nos ajudam a escrever uma grande história.
          </p>

          <Button
            classButton="primary"
            fontColor="orange-dark"
            onClick={onClick}
            target="_blank"
            text="Confira as vagas disponíveis"
          />
        </div>
      </div>
    </SectionContainer>
  )
}

const WhyWorkAtDigital = () => {
  const [getImage] = useStaticImage()

  return (
    <SectionContainer>
      <div className="why-dr">
        <h2 className="why-dr__title">Por que trabalhar na DR_</h2>
        <p className="why-dr__paragraph">
          A Digital Republic é o lugar certo para você, que deseja desenvolver a carreira
          e atingir o próximo nível do seu aprendizado profissional. Aqui,{' '}
          <b>você irá encontrar grande possibilidade de crescimento dentro da empresa:</b>{' '}
          nós valorizamos o profissional e temos estratégias para a retenção de talentos.
          <br />
          <br />
          <b>Conheça algumas das ações que proporcionamos ao nosso time:</b>
          <br />
          • Todo aniversário ou aniversário de empresa (de 6 meses, 1 ano ou 5 anos), o
          profissional ganha presentes ou experiências;
          <br /> • Temos happy hour toda última sexta-feira do mês para maior integração
          da equipe;
          <br /> • Palestras promovidas pelo DEI com temáticas atuais, como Setembro
          Amarelo (ministrada pelo Projeto HELP).
        </p>
      </div>
      <div className="why-dr__images">
        <GatsbyImage image={getImage('careers-DR.webp')} alt="escritório DR" />
        <GatsbyImage image={getImage('careers-time.webp')} alt="time DR" />
        <GatsbyImage image={getImage('careers-dr2.webp')} alt="painel DR" />
      </div>
    </SectionContainer>
  )
}

const DigitalTeamReport = () => {
  const [getImage] = useStaticImage()
  const [swiperRef, setSwiperRef] = useState()
  const [windowWidth, _] = useWindowDimensions()

  const handleLeftSwipe = useCallback(() => {
    swiperRef.slidePrev()
  }, [swiperRef])
  const handleRightSwipe = useCallback(() => {
    swiperRef.slideNext()
  }, [swiperRef])

  const Buttons = () => {
    return (
      <div className="digital-team__buttons-container">
        <button
          aria-label="prev"
          type="button"
          onClick={handleLeftSwipe}
          className="swiper-button-prev"
        >
          &nbsp;
        </button>

        <button
          aria-label="next"
          type="button"
          onClick={handleRightSwipe}
          className="swiper-button-next"
        >
          &nbsp;
        </button>
      </div>
    )
  }

  return (
    <SectionContainer className="digital-team">
      <div className="digital-team__title__container">
        <h2 className="digital-team__title">Veja o que nosso time fala sobre a DR_</h2>
        {windowWidth >= 1024 && <Buttons />}
      </div>
      <Swiper
        onSwiper={setSwiperRef}
        spaceBetween={4}
        loop
        autoHeight
        className="digital-team__swiper-container"
        modules={[EffectFade]}
      >
        {testimonialInformation.map(({ image, text, name, role }) => {
          return (
            <SwiperSlide key={`${name}-${role}`}>
              <div className="digital-team__card">
                <div>
                  <GatsbyImage
                    loading="lazy"
                    quality={95}
                    formats={['auto', 'webp', 'avif']}
                    alt={name}
                    image={getImage(image)}
                    className="digital-team__card__image"
                  />
                </div>
                <div className="digital-team__card__info-container">
                  <p className="digital-team__card__paragraph">{text}</p>
                  <p className="digital-team__card__person-info">
                    <b>{name} |</b> {role}
                  </p>
                </div>
              </div>
            </SwiperSlide>
          )
        })}
      </Swiper>
      {windowWidth < 1024 && <Buttons />}
    </SectionContainer>
  )
}

const Benefits = () => {
  const [getImage] = useStaticImage()

  return (
    <SectionContainer>
      <div className="benefits">
        <h2 className="benefits__title">Conheça todos os benefícios que oferecemos</h2>

        <div className="benefits__item-list">
          {benefitsList.map((benefit) => {
            return (
              <div key={benefit.text} className="benefits__item-list__item">
                <GatsbyImage
                  image={getImage(benefit.image)}
                  className="benefits__item-list__item__icon"
                  alt={benefit.text}
                />
                <p className="benefits__item-list__item__text">{benefit.text}</p>
              </div>
            )
          })}
        </div>
        <a href={`mailto:${EMAIL_OPPORTUNITY_CONTACT}`} className="benefits__anchor">
          Envie seu currículo direto para o RH
          <CircledArrow width="20px" />
        </a>
      </div>
    </SectionContainer>
  )
}

const Jobs = forwardRef((_, ref) => {
  const jobs = useContentfulJobs()
  const [allJobs, setAllJobs] = useState([])
  const [filters, setFilters] = useState({
    sector: '',
    vacancyType: '',
    operatingModel: '',
  })
  const [jobsPerPage, setJobsPerPage] = useState(5)
  const [activePage, setActivePage] = useState(0)

  const handleFilterValueChange = (event) => {
    const { name, value } = event.target

    setFilters({
      ...filters,
      [name]: value,
    })
  }

  const jobsFilter = (jobs, filters) => {
    return jobs.filter((job) => {
      const matchesSector = !filters.sector || job.sector === filters.sector
      const matchesVacancyType =
        !filters.vacancyType || job.vacancyType === filters.vacancyType
      const matchesOperatingModel =
        !filters.operatingModel || job.operatingModel === filters.operatingModel
      return matchesSector && matchesVacancyType && matchesOperatingModel
    })
  }

  const handleJobsPerPageChange = (event) => {
    setJobsPerPage(parseInt(event.target.value))
  }

  const workModel = jobs.map((job) => job.operatingModel)
  const sectors = jobs.map((job) => job.sector)
  const vacancyType = jobs.map((job) => job.vacancyType)

  useEffect(() => {
    const filteredJobs = jobsFilter(jobs, filters)
    setAllJobs(filteredJobs)
  }, [jobs, filters, setAllJobs])

  return (
    <SectionContainer>
      <article className="jobs">
        <div className="jobs__filter" ref={ref}>
          <Filter
            handleFilterChange={handleFilterValueChange}
            options={['Todas as opções', ...vacancyType]}
            orientation={'vertical'}
            title={'Tipo de vaga'}
            name={'vacancyType'}
          />
          <Filter
            handleFilterChange={handleFilterValueChange}
            options={['Todas as opções', ...workModel]}
            orientation={'vertical'}
            title={'Modelo de trabalho'}
            name={'operatingModel'}
          />
          <Filter
            handleFilterChange={handleFilterValueChange}
            options={['Todas as opções', ...sectors]}
            orientation={'vertical'}
            title={'Departamento'}
            name={'sector'}
          />
        </div>

        <div className="jobs__header">
          <p className="jobs__quantity">{allJobs.length} vaga(s)</p>
          <Filter
            title={'Itens por página'}
            orientation={'horizontal'}
            handleFilterChange={handleJobsPerPageChange}
            value={jobsPerPage}
            options={[5, 10, 15]}
            size={'--sm'}
          />
        </div>

        {allJobs.length === 0 ? (
          <p className="jobs__no-vacancy">
            Infelizmente não temos vagas abertas no momento ☹️
          </p>
        ) : (
          <JobsPagination
            allJobs={allJobs}
            jobsPerPage={jobsPerPage}
            activePage={activePage}
            setActivePage={setActivePage}
          />
        )}
      </article>
    </SectionContainer>
  )
})

const Careers = () => {
  const jobsRef = useRef(null)
  return (
    <Layout page="Carreiras">
      <Slogan>
        <b>Carreiras</b>
      </Slogan>
      <Banner
        onClick={() =>
          jobsRef.current?.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
            inline: 'center',
          })
        }
      />
      <HighLight />
      <Jobs ref={jobsRef} />
      <WhyWorkAtDigital />
      <DigitalTeamReport />
      <VideoPlayer videoSource={drInstitucional} withoutStyle drInstitucional />
      <Benefits />
      <WorkWithUs />
    </Layout>
  )
}

export const Head = () => <Seo title="Carreiras" />

export default Careers
