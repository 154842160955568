import { Link } from 'gatsby'
import React, { useEffect, useId, useState } from 'react'
import ReactPaginate from 'react-paginate'
import './styles.scss'

const JobItem = ({path, location, operatingModel, title}) => {
  const id = useId()
  return (
    <li className="jobs__list-item" key={id}>
      <Link className="jobs__title" target={'_blank'} to={path}>
        {title}
      </Link>
      <p className="jobs__location">{location}</p>
      <div className="jobs__content-wrapper">
        <p className='jobs__operating-model'>{operatingModel}</p>
      </div>
    </li>
  )
}

const JobsPagination = ({ allJobs, jobsPerPage, activePage, setActivePage }) => {
  const [itemOffset, setItemOffset] = useState(0)
  const [pageCount, setPageCount] = useState(0)
  const [jobsToBeShown, setJobsToBeShown] = useState([])
  const hasMoreThanOnePage = allJobs.length >= jobsPerPage

  const onPageChange = (event) => {
    const newOffset = (event.selected * jobsPerPage) % allJobs.length
    setItemOffset(newOffset)
    setActivePage(event.selected)
  }

  useEffect(() => {
    const endOffset = itemOffset + jobsPerPage
    setJobsToBeShown(allJobs.slice(itemOffset, endOffset))
    setPageCount(Math.ceil(allJobs.length / jobsPerPage))
  }, [allJobs, setJobsToBeShown, setPageCount, itemOffset, jobsPerPage])

  useEffect(() => {
    if (activePage === 0) {
      setItemOffset(0)
    }
  }, [activePage])

  return (
    <>
      <ul className="jobs__list">
        {jobsToBeShown.map((job) => (
          <JobItem 
            location={job.location}
            operatingModel={job.operatingModel}
            path={job.path}
            title={job.title}
          />
        ))}
      </ul>
      {hasMoreThanOnePage && (
        <ReactPaginate
          nextLabel={<span id="buttonNext" />}
          previousLabel={<span id="buttonPrevious" />}
          pageCount={pageCount}
          onPageChange={onPageChange}
          pageRangeDisplayed={3}
          marginPagesDisplayed={2}
          pageClassName="jobs-pagination__controllers--item"
          previousClassName="jobs-pagination__controllers--previous"
          nextClassName="jobs-pagination__controllers--next"
          breakClassName="jobs-pagination__controllers--item"
          containerClassName="jobs-pagination__controllers"
          activeClassName="active"
          renderOnZeroPageCount={null}
          forcePage={activePage}
        />
      )}
    </>
  )
}

export default JobsPagination
