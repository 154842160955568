import React, { useEffect, useRef, useState } from 'react'
import SectionContainer from '../SectionContainer'
import useStaticImage from '../../../hooks/useStaticImage'
import { GatsbyImage } from 'gatsby-plugin-image'

import './styles.scss'
import { isMobile } from 'react-device-detect'

export default function VideoPlayer({ withoutStyle, videoSource, drInstitucional }) {
  const [isFirstPlay, setIsFirstPlay] = useState(true)
  const [isFullscreen, setIsFullscreen] = useState(false)
  const [isPlaying, setIsPlaying] = useState(false)
  const [lastVolume, setLastVolume] = useState(0.5)
  const [isMuted, setIsMuted] = useState(false)
  const [currentTime, setCurrentTime] = useState('00:00')
  const [videoDuration, setVideoDuration] = useState(0)
  const playerRef = useRef(null)
  const volumeRef = useRef(null)
  const videoTimelineRef = useRef(null)
  const progressBarRef = useRef(null)
  const containerRef = useRef(null)
  const [getImage] = useStaticImage()

  useEffect(() => {
    const player = playerRef.current,
      progressBar = progressBarRef.current,
      videoTimeline = videoTimelineRef.current

    if (!player || !progressBar || !videoTimeline) {
      return
    }

    volumeRef.current.value = lastVolume
    player.volume = lastVolume

    const handleTimeUpdate = (e) => {
      const { currentTime, duration } = e.target
      const percentage = (currentTime / duration) * 100

      progressBar.style.width = `${percentage}%`
      setCurrentTime(() => formatTime(currentTime))
    }

    const handleTimeline = (e) => {
      const timelineWidth = videoTimeline.clientWidth
      player.currentTime = Math.round((e.offsetX / timelineWidth) * player.duration + 1)
    }

    player.addEventListener('timeupdate', handleTimeUpdate)
    videoTimeline.addEventListener('click', handleTimeline)

    return () => {
      player.removeEventListener('timeupdate', handleTimeUpdate)
      videoTimeline.removeEventListener('click', handleTimeline)
    }
  }, [])

  function formatTime(time) {
    let seconds = Math.floor(time % 60),
      minutes = Math.floor(time / 60) % 60,
      hours = Math.floor(time / 3600)
      const padTimeUnit = (timeUnit) => timeUnit < 10 ? `0${timeUnit}` : timeUnit

    seconds = padTimeUnit(seconds)
    minutes = padTimeUnit(minutes)
    hours = padTimeUnit(hours)

    if (hours === 0) {
      return `${minutes}:${seconds}`
    }
    return `${hours}:${minutes}:${seconds}`
  }

  function handleLoadedMetadata() {
    setVideoDuration(() => formatTime(playerRef.current.duration))
  }

  function togglePlaying() {
    setIsPlaying((prev) => !prev)
    setIsFirstPlay(() => false)

    if (isPlaying) {
      playerRef.current.pause()
      return
    }

    playerRef.current.play()
  }

  function toggleFullscreen() {
    if (isFullscreen) {
      setIsFullscreen(() => false)
      document.exitFullscreen()
      return
    }

    setIsFullscreen(() => true)
    containerRef.current.requestFullscreen()
  }

  function changeVolume(e) {
    playerRef.current.volume = e.target.value
    setLastVolume(() => e.target.value)

    if (e.target.value <= 0) {
      return setIsMuted(() => true)
    }

    setIsMuted(() => false)
  }

  function onMute() {
    playerRef.current.volume = isMuted ? lastVolume : 0
    volumeRef.current.value = isMuted ? lastVolume : 0
    setIsMuted(!isMuted)
  }

  if (isMobile && !drInstitucional) {
    return (
      <video
        controls
        playsInline
        src={`${videoSource}#t=0.1`}
        preload="metadata"
        style={{ width: '100%', marginTop: 15 }}
      />
    )
  }

  return withoutStyle ? (
    <div
      className={`container show-controls${drInstitucional ? ' dr-video' : ''}`}
      ref={containerRef}
    >
      {isFirstPlay && (
        <div className="overlay" onClick={togglePlaying}>
          <GatsbyImage
            style={{ margin: 0 }}
            image={getImage('play-icon.webp')}
            alt="play icon"
          />
        </div>
      )}
      <div className={`wrapper ${isFirstPlay && 'wrapper__hidden'}`}>
        <div className="video-timeline">
          <div className="progress-area" ref={videoTimelineRef}>
            <div className="progress-bar" ref={progressBarRef}></div>
          </div>
        </div>
        <ul className="video-controls">
          <li className="options left">
            <button type="button" className="volume" onClick={() => onMute()}>
              <i className="icon">
                {isMuted ? (
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
                    <path
                      fill="#ffffff"
                      d="M301.1 34.8C312.6 40 320 51.4 320 64V448c0 12.6-7.4 24-18.9 29.2s-25 3.1-34.4-5.3L131.8 352H64c-35.3 0-64-28.7-64-64V224c0-35.3 28.7-64 64-64h67.8L266.7 40.1c9.4-8.4 22.9-10.4 34.4-5.3zM425 167l55 55 55-55c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9l-55 55 55 55c9.4 9.4 9.4 24.6 0 33.9s-24.6 9.4-33.9 0l-55-55-55 55c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9l55-55-55-55c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0z"
                    />
                  </svg>
                ) : (
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
                    <path
                      fill="#ffffff"
                      d="M533.6 32.5C598.5 85.3 640 165.8 640 256s-41.5 170.8-106.4 223.5c-10.3 8.4-25.4 6.8-33.8-3.5s-6.8-25.4 3.5-33.8C557.5 398.2 592 331.2 592 256s-34.5-142.2-88.7-186.3c-10.3-8.4-11.8-23.5-3.5-33.8s23.5-11.8 33.8-3.5zM473.1 107c43.2 35.2 70.9 88.9 70.9 149s-27.7 113.8-70.9 149c-10.3 8.4-25.4 6.8-33.8-3.5s-6.8-25.4 3.5-33.8C475.3 341.3 496 301.1 496 256s-20.7-85.3-53.2-111.8c-10.3-8.4-11.8-23.5-3.5-33.8s23.5-11.8 33.8-3.5zm-60.5 74.5C434.1 199.1 448 225.9 448 256s-13.9 56.9-35.4 74.5c-10.3 8.4-25.4 6.8-33.8-3.5s-6.8-25.4 3.5-33.8C393.1 284.4 400 271 400 256s-6.9-28.4-17.7-37.3c-10.3-8.4-11.8-23.5-3.5-33.8s23.5-11.8 33.8-3.5zM301.1 34.8C312.6 40 320 51.4 320 64V448c0 12.6-7.4 24-18.9 29.2s-25 3.1-34.4-5.3L131.8 352H64c-35.3 0-64-28.7-64-64V224c0-35.3 28.7-64 64-64h67.8L266.7 40.1c9.4-8.4 22.9-10.4 34.4-5.3z"
                    />
                  </svg>
                )}
              </i>
            </button>
            <input
              type="range"
              min="0"
              max="1"
              step="any"
              onChange={changeVolume}
              ref={volumeRef}
            />
            <section className="video-timer">
              <p className="current-time">{currentTime}</p>
              <p className="separator"> / </p>
              <p className="video-duration">{videoDuration}</p>
            </section>
          </li>
          <li className="options center">
            <button className="play-pause" onClick={togglePlaying}>
              <i className="icon">
                {isPlaying ? (
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
                    <path
                      fill="#ffffff"
                      d="M48 64C21.5 64 0 85.5 0 112V400c0 26.5 21.5 48 48 48H80c26.5 0 48-21.5 48-48V112c0-26.5-21.5-48-48-48H48zm192 0c-26.5 0-48 21.5-48 48V400c0 26.5 21.5 48 48 48h32c26.5 0 48-21.5 48-48V112c0-26.5-21.5-48-48-48H240z"
                    />
                  </svg>
                ) : (
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
                    <path
                      fill="#ffffff"
                      d="M73 39c-14.8-9.1-33.4-9.4-48.5-.9S0 62.6 0 80V432c0 17.4 9.4 33.4 24.5 41.9s33.7 8.1 48.5-.9L361 297c14.3-8.7 23-24.2 23-41s-8.7-32.2-23-41L73 39z"
                    />
                  </svg>
                )}
              </i>
            </button>
          </li>
          <li className="options right">
            <button className="fullscreen" onClick={toggleFullscreen}>
              <i className="icon">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                  <path
                    fill="#ffffff"
                    d="M32 32C14.3 32 0 46.3 0 64v96c0 17.7 14.3 32 32 32s32-14.3 32-32V96h64c17.7 0 32-14.3 32-32s-14.3-32-32-32H32zM64 352c0-17.7-14.3-32-32-32s-32 14.3-32 32v96c0 17.7 14.3 32 32 32h96c17.7 0 32-14.3 32-32s-14.3-32-32-32H64V352zM320 32c-17.7 0-32 14.3-32 32s14.3 32 32 32h64v64c0 17.7 14.3 32 32 32s32-14.3 32-32V64c0-17.7-14.3-32-32-32H320zM448 352c0-17.7-14.3-32-32-32s-32 14.3-32 32v64H320c-17.7 0-32 14.3-32 32s14.3 32 32 32h96c17.7 0 32-14.3 32-32V352z"
                  />
                </svg>
              </i>
            </button>
          </li>
        </ul>
      </div>
      <video
        ref={playerRef}
        preload="auto"
        src={videoSource}
        onLoadedMetadata={handleLoadedMetadata}
      />
    </div>
  ) : (
    <SectionContainer centered>
      <div className="container show-controls" ref={containerRef}>
        {isFirstPlay && (
          <div className="overlay" onClick={togglePlaying}>
            <GatsbyImage image={getImage('play-icon.webp')} alt="play icon" />
          </div>
        )}
        <div className={`wrapper ${isFirstPlay && 'wrapper__hidden'}`}>
          <div className="video-timeline">
            <div className="progress-area" ref={videoTimelineRef}>
              <div className="progress-bar" ref={progressBarRef}></div>
            </div>
          </div>
          <ul className="video-controls">
            <li className="options left">
              <button type="button" className="volume">
                <i className="icon">
                  {isMuted ? (
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
                      <path
                        fill="#ffffff"
                        d="M301.1 34.8C312.6 40 320 51.4 320 64V448c0 12.6-7.4 24-18.9 29.2s-25 3.1-34.4-5.3L131.8 352H64c-35.3 0-64-28.7-64-64V224c0-35.3 28.7-64 64-64h67.8L266.7 40.1c9.4-8.4 22.9-10.4 34.4-5.3zM425 167l55 55 55-55c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9l-55 55 55 55c9.4 9.4 9.4 24.6 0 33.9s-24.6 9.4-33.9 0l-55-55-55 55c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9l55-55-55-55c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0z"
                      />
                    </svg>
                  ) : (
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
                      <path
                        fill="#ffffff"
                        d="M533.6 32.5C598.5 85.3 640 165.8 640 256s-41.5 170.8-106.4 223.5c-10.3 8.4-25.4 6.8-33.8-3.5s-6.8-25.4 3.5-33.8C557.5 398.2 592 331.2 592 256s-34.5-142.2-88.7-186.3c-10.3-8.4-11.8-23.5-3.5-33.8s23.5-11.8 33.8-3.5zM473.1 107c43.2 35.2 70.9 88.9 70.9 149s-27.7 113.8-70.9 149c-10.3 8.4-25.4 6.8-33.8-3.5s-6.8-25.4 3.5-33.8C475.3 341.3 496 301.1 496 256s-20.7-85.3-53.2-111.8c-10.3-8.4-11.8-23.5-3.5-33.8s23.5-11.8 33.8-3.5zm-60.5 74.5C434.1 199.1 448 225.9 448 256s-13.9 56.9-35.4 74.5c-10.3 8.4-25.4 6.8-33.8-3.5s-6.8-25.4 3.5-33.8C393.1 284.4 400 271 400 256s-6.9-28.4-17.7-37.3c-10.3-8.4-11.8-23.5-3.5-33.8s23.5-11.8 33.8-3.5zM301.1 34.8C312.6 40 320 51.4 320 64V448c0 12.6-7.4 24-18.9 29.2s-25 3.1-34.4-5.3L131.8 352H64c-35.3 0-64-28.7-64-64V224c0-35.3 28.7-64 64-64h67.8L266.7 40.1c9.4-8.4 22.9-10.4 34.4-5.3z"
                      />
                    </svg>
                  )}
                </i>
              </button>
              <input
                type="range"
                min="0"
                max="1"
                step="any"
                onChange={changeVolume}
                ref={volumeRef}
              />
              <div className="video-timer">
                <p className="current-time">{currentTime}</p>
                <p className="separator"> / </p>
                <p className="video-duration">{videoDuration}</p>
              </div>
            </li>
            <li className="options center">
              <button className="play-pause" onClick={togglePlaying}>
                <i className="icon">
                  {isPlaying ? (
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
                      <path
                        fill="#ffffff"
                        d="M48 64C21.5 64 0 85.5 0 112V400c0 26.5 21.5 48 48 48H80c26.5 0 48-21.5 48-48V112c0-26.5-21.5-48-48-48H48zm192 0c-26.5 0-48 21.5-48 48V400c0 26.5 21.5 48 48 48h32c26.5 0 48-21.5 48-48V112c0-26.5-21.5-48-48-48H240z"
                      />
                    </svg>
                  ) : (
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
                      <path
                        fill="#ffffff"
                        d="M73 39c-14.8-9.1-33.4-9.4-48.5-.9S0 62.6 0 80V432c0 17.4 9.4 33.4 24.5 41.9s33.7 8.1 48.5-.9L361 297c14.3-8.7 23-24.2 23-41s-8.7-32.2-23-41L73 39z"
                      />
                    </svg>
                  )}
                </i>
              </button>
            </li>
            <li className="options right">
              <button className="fullscreen" onClick={toggleFullscreen}>
                <i className="icon">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                    <path
                      fill="#ffffff"
                      d="M32 32C14.3 32 0 46.3 0 64v96c0 17.7 14.3 32 32 32s32-14.3 32-32V96h64c17.7 0 32-14.3 32-32s-14.3-32-32-32H32zM64 352c0-17.7-14.3-32-32-32s-32 14.3-32 32v96c0 17.7 14.3 32 32 32h96c17.7 0 32-14.3 32-32s-14.3-32-32-32H64V352zM320 32c-17.7 0-32 14.3-32 32s14.3 32 32 32h64v64c0 17.7 14.3 32 32 32s32-14.3 32-32V64c0-17.7-14.3-32-32-32H320zM448 352c0-17.7-14.3-32-32-32s-32 14.3-32 32v64H320c-17.7 0-32 14.3-32 32s14.3 32 32 32h96c17.7 0 32-14.3 32-32V352z"
                    />
                  </svg>
                </i>
              </button>
            </li>
          </ul>
        </div>
        <video
          ref={playerRef}
          preload="auto"
          src={videoSource}
          onLoadedMetadata={handleLoadedMetadata}
        ></video>
      </div>
    </SectionContainer>
  )
}
